<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Assignments") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getAllData(page)"
                    v-model.trim="search"
                  />

                  <v-icon
                    :title="$t('Search')"
                    @click="getAllData(page)"
                    class="search-icon"
                    >search</v-icon
                  >
                </label>

                <span :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <!-- quarters -->
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Quarters')"
                          :items="allQuarters"
                          item-text="name"
                          item-value="id"
                          v-model="filter.quarters"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.states"
                          :label="$t('State')"
                          :items="states"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select" v-if="!teacher">
                        <v-autocomplete
                          v-model="filter.teachers"
                          :label="$t('Teacher')"
                          :items="teachers"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select" v-if="!student">
                        <v-autocomplete
                          v-model="filter.grade"
                          :label="$t('Grade')"
                          :items="grades"
                          @change="hideLabel = true && getClasses()"
                          item-text="name"
                          item-value="id"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select" v-if="!student">
                        <v-autocomplete
                          v-model="filter.classes"
                          :label="$t('Class')"
                          :items="classes"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.subjects"
                          :label="$t('Subjects')"
                          :items="subjects"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->
                <v-icon
                  large
                  v-if="permissions.add"
                  @click="OpenDialogAddEdit"
                  class="main-color"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >
                <v-icon
                  class="main-color"
                  large
                  @click="prentListPDF()"
                  style="cursor: pointer"
                  :title="$t('Print')"
                  >print</v-icon
                >

                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/assignment"
                  v-if="activitylogPermission"
                  target="_blank"
                  class="mt-2"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <!-- title -->
          <template v-slot:item.title="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.title.slice(0, 30) }}
                </p>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>

          <!-- classes -->
          <template v-slot:item.classes="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.classes.slice(0, 30) }}
                </p>
              </template>
              <span>{{ item.classes }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.actions="{ item }">
            <!-- v-if="permissions.edit"
              @click="edit(item)" 
               :id="item.id"
              -->

            <v-icon
              class="main-color"
              :title="$t('Edit Assignment Details')"
              @click="edit(item)"
              v-if="permissions.edit && item.can_edit_details"
              :id="item.id"
              >edit</v-icon
            >
            <v-btn
              icon
              :title="$t('Edit Assignment Questions')"
              width="25px"
              height="25px"
              @click="openHomeWorkEdit(item.id)"
              v-if="permissions.edit && item.can_delete"
            >
              <img
                src="../../assets/elearning-online-gray.svg"
                width="20px"
                alt
              />
            </v-btn>
            <v-btn
              icon
              :to="'/StudentHomeWorkView/' + item.id"
              v-if="student && !item.is_submited"
              :title="$t('Answer Assignment')"
            >
              <v-icon class="main-color">remove_red_eye</v-icon></v-btn
            >
            <v-btn
              icon
              :to="'/studentHomeworkdetails/' + item.id"
              v-if="student && item.is_submited == true"
              title="View Assignment Details"
            >
              <v-icon class="main-color">question_answer</v-icon></v-btn
            >

            <v-icon
              v-if="permissions.delete && item.can_delete"
              class="main-color"
              :id="item.id"
              @click="openDialog(item)"
              >delete</v-icon
            >

            <router-link
              :to="'/studentshomeworkresults/' + item.id"
              :title="$t('Student Results')"
              v-if="!student"
            >
              <v-icon>assignment</v-icon>
            </router-link>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">Close</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>

    <AddEditHomeWorkComponent
      :editedItem="editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' Assignments'"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.apiEndPoints"
      :teacher="teacher"
    ></AddEditHomeWorkComponent>

    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delete the Assignment?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editedItem)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AddEditHomeWorkComponent from "../../components/modals/AddEditHomeWorkComponent";
import axios from "axios";
import ACL from "../../acl";
export default {
  name: "homework",
  components: { AddEditHomeWorkComponent },
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),

      options: {
        itemsPerPage: 15,
      },
      snack: "",
      teacher: ACL.Role("Teacher"),
      student: ACL.Role("Student"),
      parent: ACL.Role("Parent"),
      types: [],
      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      formTitle: "",
      selected: [],
      headers:
        ACL.Role("Student") == true
          ? [
              {
                text: this.$i18n.t("Title"),
                value: "title",
                sortable: false,
              },
              {
                text: this.$i18n.t("Subject"),
                value: "subject",
                sortable: false,
              },
              {
                text: this.$i18n.t("Grade"),
                value: "grade",
                sortable: false,
              },
              {
                text: this.$i18n.t("Classes"),
                value: "classes",
                sortable: false,
              },
              {
                text: this.$i18n.t("Publish Date"),
                value: "publish_date",
                sortable: false,
              },
              {
                text: this.$i18n.t("Due Date"),
                value: "due_date",
                sortable: false,
              },

              {
                text: this.$i18n.t("State"),
                value: "state",
                sortable: false,
              },
              {
                text: this.$i18n.t("Submit date"),
                value: "submission_time",
                sortable: false,
              },
              {
                text: this.$i18n.t("Mark"),
                value: "student_mark",
                sortable: false,
              },
              {
                text: this.$i18n.t("Teacher"),
                value: "teacher",
                sortable: false,
              },

              {
                text: this.$i18n.t("Actions"),
                value: "actions",
                sortable: false,
              },
            ]
          : [
              {
                text: this.$i18n.t("Title"),
                value: "title",
                sortable: false,
              },
              {
                text: this.$i18n.t("Subject"),
                value: "subject",
                sortable: false,
              },
              {
                text: this.$i18n.t("Grade"),
                value: "grade",
                sortable: false,
              },
              {
                text: this.$i18n.t("Classes"),
                value: "classes",
                sortable: false,
              },
              {
                text: this.$i18n.t("Publish Date"),
                value: "publish_date",
                sortable: false,
              },
              {
                text: this.$i18n.t("Due Date"),
                value: "due_date",
                sortable: false,
              },

              {
                text: this.$i18n.t("State"),
                value: "state",
                sortable: false,
              },
              {
                text: this.$i18n.t("Teacher"),
                value: "teacher",
                sortable: false,
              },

              {
                text: this.$i18n.t("Actions"),
                value: "actions",
                sortable: false,
              },
            ],
      title: "Home Work",
      apiEndPoints: {
        list: "homework",
        create: "homework/store",
        edit: "homework/updateAssignment",
        delete: "accounts/destroy",
      },
      editedItem: {
        id: "",
        title: "",
        mark: "",
        academic_grade_year_id: "",
        academic_grade_subjects_id: "",
        class_ids: [],
        teacher_id: "",
        publish_date: "",
        due_date: "",
        auto_correction: "",
        randomiz: "",
        allow_multi_submissions: "",
        email_notification_reminder: true,
        email_notification_comments: "",
      },
      permissions: {
        list: ACL.checkPermission("list-home-work"),
        add: ACL.checkPermission("add-home-work"),
        edit: ACL.checkPermission("edit-home-work"),
        delete: ACL.checkPermission("delete-home-work"),
      },

      items: [],
      filter: {
        teachers: [],
        states: "Active",
        subjects: [],
        grade: "",
        classes: [],
        quarters: "",
      },
      search: "",
      teachers: [],
      subjects: [],
      classes: [],
      grades: [],
      allQuarters: [],
      states: [
        this.$i18n.t("All"),
        this.$i18n.t("New"),
        this.$i18n.t("Active"),
        this.$i18n.t("Completed"),
      ],
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      AddEditdialog: false,
      editedIndex: -1,
      confirmDialoag: false,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function () {
      this.$router.push(
        {
          path: "/homework?page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    onChildClick(value, item, index) {
      this.AddEditdialog = value;
      if (item != null && index == -1) {
        this.items.push(item);
        this.totalItems = this.totalItems + 1;

        this.length = Math.ceil(this.totalItems / this.options.itemsPerPage);
        if (this.page < this.length) {
          this.page = this.length;
        }
      } else if (index > -1)
        if (item != null) Object.assign(this.items[index], item);
      if (value == false) {
        this.items = [];
        this.$nextTick(function () {
          this.getAllData();
        });
      }
    },
    edit(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = "Edit";
      this.AddEditdialog = true;
    },

    openDialog(item) {
      // console.log(item);
      this.dialogReset = true;
      this.editedItem = item;
    },
    deleteItem(item) {
      // console.log(item);
      axios
        .get(this.getApiUrl + "/homework/destroy/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.dialogReset = false;
          console.log(response.data);
          this.snack = true;
          this.snackColor = "green";
          this.snackText = response.data.status.message;
          this.getAllData();
        });
    },
    OpenDialogAddEdit() {
      this.editedIndex = -1;
      // this.table.editedItem = {};
      if (this.editedItem.en) {
        Object.keys(this.editedItem.en).forEach(
          (k) => (this.editedItem.en[k] = "")
        );
        Object.keys(this.editedItem).forEach((k) => {
          if (k != "en" && k != "ar") this.editedItem[k] = "";
        });
      } else {
        Object.keys(this.editedItem).forEach((k) => (this.editedItem[k] = ""));
      }
      if (this.editedItem.ar) {
        Object.keys(this.editedItem.ar).forEach(
          (k) => (this.editedItem.ar[k] = "")
        );
      }
      this.editedItem.email_notification_reminder = true;
      this.formTitle = "Add";
      this.AddEditdialog = true;
    },

    openHomeWorkEdit(homeworkId) {
      this.$router.push("/homework/" + homeworkId + "?edit=true");
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },

    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl + "/homework?search=" + this.search + "&page=" + page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    prentListPDF() {
      axios
        .get(
          this.getApiUrl +
            "/homework/printAssignmentList/?search=" +
            this.search,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          var link = response.data.data;
          // console.log(link);
          window.open(link, "_blank");
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getTeachers() {
      axios
        .get(this.getApiUrl + "/homework/getTeachers", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.teachers = response.data.data;
        });
    },
    // getSubjects() {
    //   axios
    //     .get(this.getApiUrl + "/homework/getAllSubjects", {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.token,
    //       },
    //     })
    //     .then((response) => {
    //       this.subjects = response.data.data;
    //     });
    // },
    getgrades() {
      axios
        .get(this.getApiUrl + "/homework/getGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
        });
    },
    getStudentGrade() {
      axios
        .get(this.getApiUrl + "/homework/getStudentGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
          this.filter.grade = this.grades[0].id;
          this.getClasses();
        });
    },
    getClasses() {
      axios
        .get(this.getApiUrl + "/homework/getClasses/" + this.filter.grade, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data.classes;
          this.subjects = response.data.data.subjects;
        });
    },
    getQuarters() {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allQuarters = response.data.data.quarters;

          //  this.filter.quarters =[];
          this.filter.quarters = response.data.data.currntQuarterid;
        });
    },
  },
  mounted() {
    this.getTeachers();
    this.getQuarters();
    // this.getSubjects();
    if (this.student) {
      this.getStudentGrade();
    } else {
      this.getgrades();
    }

    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getAllData(this.page);
    } else {
      this.page = 1;
      this.getAllData(this.page);
    }
    if (this.student) {
      // console.log("student");
      this.states.splice(1, 1);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

#wrapper {
  transition: all 0.4s ease 0s;
}

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

.select {
  margin-top: 20px !important;
}
</style>
